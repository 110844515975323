export var scales = {
    SM: "sm",
    MD: "md",
    LG: "lg"
};
export var scaleKeys = {
    handleHeight: "handleHeight",
    handleWidth: "handleWidth",
    handleLeft: "handleLeft",
    handleTop: "handleTop",
    checkedLeft: "checkedLeft",
    toggleHeight: "toggleHeight",
    toggleWidth: "toggleWidth"
};
