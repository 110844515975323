import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
   {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'ICOL',
    lpAddresses: {
      6552: '0xfdA0F4361258dFD4Cc7f936285874605e46a490D',
      65450: '0xCc3FEba5d10Cb33528908143f613880838F7A690',
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wscol,
  },
  {
    pid: 2,
    // v1pid: 1,
    lpSymbol: 'ICOL-SCOL LP',
    lpAddresses: {
      6552: '0x04a87d67A487C38089736C738aEBc13D6B981c76',
      65450: '0xe4646f4824c77dfAd79BE78c3082D4f94BDdDBe8',
    },
    token: serializedTokens.icol,
    quoteToken: serializedTokens.wscol,
  },
  {
    pid: 3,
    // v1pid: 2,
    lpSymbol: 'BUSD-SCOL LP',
    lpAddresses: {
      6552: '0xc8EdeC730CC6aBb57c213c840633071164357357',
      65450: '0x6e55cE1D2Ac78d7f32cEF08CbD0658Cd02E8B7cE',
    },
    token: serializedTokens.nusd,
    quoteToken: serializedTokens.wscol,
  },
  //    * V3 by order of release (some may be out of PID order due to multiplier boost)
  {
    pid: 4,
    // v1pid: 3,
    lpSymbol: 'USDT-SCOL LP',
    lpAddresses: {
      6552: '0x0DC8b02c393B3FD37dA5B3944Ad9d301377C5849',
      65450: '0xfA6785989E200a0362BB5fB76c0A1B5e47Cff53e',
    },
    token: serializedTokens.nusdt,
    quoteToken: serializedTokens.wscol,
  },
  // {
  //   pid: 5,
  //   lpSymbol: 'ETH-SCOL LP',
  //   lpAddresses: {
  //     6552: '',
  //     65450: '0xD30916af2D1E8F5da477057A95958e724528bcCa',
  //   },
  //   token: serializedTokens.eth,
  //   quoteToken: serializedTokens.wscol,
  // },


  
].filter((f) => !!f.lpAddresses[CHAIN_ID])

export default farms
